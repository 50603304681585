/**
 * Sources
 */

import React from 'react';
import get from 'lodash/get';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { ContentParser } from '@components';

const Sources = () => {
  const sources = get(useStaticQuery(graphql`
    query GetAllSourcePages {
      allWordpressPage(filter: { template: { eq: "page-source.php"} }) {
        edges {
          node {
            title
            path
            content
            acf {
              source {
                type {
                  name
                  slug
                }
                author
                author_short_version
                year
                title
                summary
                conclusions
                taxonomies {
                  name
                  slug
                }
              }
            }
          }
        }
      }
    }
  `),
  'allWordpressPage.edges', []);

  if (!sources.length) {
    return <div>No sources yet.</div>;
  }

  return (
    <div className="page__block--cards-list">
      {
        sources.map((source, index) => {
          const path = get(source.node, 'path', null);
          const title = get(source.node, 'title', null);
          const content = get(source.node, 'content', null);
          const author_short_version = get(source.node, 'acf.source.author_short_version', null);
          const year = get(source.node, 'acf.source.year', null);
          const type = get(source.node, 'acf.source.type[0].name', null);
          const conclusions = get(source.node, 'acf.source.conclusions', null);
          const sub = conclusions || content;

          return (
            <Link className="card card--preview" to={path} key={index}>
              {title && (
                <div className="card--preview__title">
                  “{title}” — {author_short_version}, {year} {type && `(${type})`} {/* eslint-disable-line*/}
                </div>
              )}
              {sub && (<ContentParser className="card--preview__subtitle" content={sub} />)}
            </Link>
          );
        })
      }
    </div>
  );
}

export default Sources;
